<template>
  <div>
    <vx-card class="mb-4">
      <div class="grid md:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 flex gap-2">
        <div class="">
          <vs-input
            id="name"
            class="w-full required"
            :label="$t('classroom.name')"
            v-model="model.name"/>
        </div>
      </div>
      <div class="grid md:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 flex gap-2">
        <div class="">
          <vs-input
            id="room_prefix"
            class="w-full required"
            :label="$t('classroom.room_prefix')"
            v-model="model.room_prefix"/>
        </div>
        <div class="flex">
          <vs-input
            id="total"
            type="number"
            class="w-full required"
            :label="$t('classroom.total')"
            v-model="model.total"/>
        </div>
        <div class="flex">
          <vs-input
            id="quantity_per_room"
            type="number"
            class="w-full required"
            :label="$t('classroom.quantity_per_room')"
            v-model="model.quantity_per_room"/>
        </div>
        <div class="flex">
          <vs-input
            id="quantity_total"
            type="number"
            class="readonly"
            :label="$t('classroom.quantity_total')"
            v-model="capacity_total"
            disabled/>
        </div>
      </div>
      <div class="flex w-full align-baseline" v-if="isAdmin">
        <select-suggestion
          :max="20"
          class="flex-grow required"
          column="name"
          model="Institution"
          :label="$t('fields.institution')"
          v-model="institution"
          :appendClearOption="true"
          ref="select_institution"
          placeholderText="Digite o nome da instituição"/>
      </div>
      <div class="flex w-full align-baseline">
        <select-suggestion
          :label="$t('Questionnaire')"
          :class="questionnaire ? 'flex-grow' : 'flex-grow required'"
          column="name,description"
          model="ContentQuestionnaire"
          v-model="questionnaire"
          :appendClearOption="true"
          ref="select_questionnaire"
          placeholderText="Digite o nome do questionário"/>
      </div>
      <div class="w-full flex gap-2 justify-end mt-4">
        <vs-button
          v-if="!isEdit"
          v-permission="'classroom_configs.create'"
          :disabled="!validateForm"
          class="float-right mr-2"
          @click="createOrUpdate">{{ $t('action.save') }}</vs-button>
          <vs-button
            v-if="isEdit"
            v-permission="'classroom_configs.edit'"
            :disabled="!validateForm"
            class="float-right mr-2"
            @click="createOrUpdate">{{ $t('action.save') }}</vs-button>
        <vs-button
          class="float-right"
          color="primary"
          type="border"
          @click="goback">{{ $t('action.back') }}</vs-button>
      </div>
    </vx-card>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'

import ClassroomConfigService from '@/services/api/ClassroomConfigService'


export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectSuggestion
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    institution: null,
    questionnaire: null,
    model: {
      id: null,
      name : '',
      room_prefix: '',
      total: null,
      quantity_per_room: null,
    }
  }),
  computed: {
    isEdit(){
      return !_.isNil(this.id)
    },
    isAdmin() {
      return isAdmin()
    },
    validateForm() {
      const valid = !this.isEmpty(this.model.name)
                    && !this.isEmpty(this.model.room_prefix)
                    && !this.isEmpty(this.model.total)
                    && !this.isEmpty(this.model.quantity_per_room)
                    && (this.questionnaire && this.questionnaire.id)
      if(this.isAdmin){
        return valid && (this.institution && this.institution.id)
      }else{
        return valid;
      }

    },
    capacity_total(){
      if(this.model.total && this.model.quantity_per_room){
        return parseInt(this.model.total) * parseInt(this.model.quantity_per_room)
      }else{
        return 0
      }
    }
  },
  beforeMount() {
    this.service = ClassroomConfigService.build(this.$vs)
  },
  mounted() {
    if (this.id) {
      this.loadData(this.id)
    }
  },

  methods: {
    requiredClass(whichData) {
      return !this.isEmpty(whichData) ? 'w-full' : 'w-full required'
    },
    loadData(id) {
      this.$vs.loading()

      this.service.read(id).then(
        response => {
          this.model = response
          this.institution = response.institution
          this.questionnaire = response.questionnaire
        },
        error => {this.$vs.loading.close()}
      ).finally(() => {this.$vs.loading.close()})
    },
    createOrUpdate() {
      this.$vs.loading()

      const modelData = {
        id: this.model.id,
        name: this.model.name,
        room_prefix: this.model.room_prefix,
        total: this.model.total,
        quantity_per_room: this.model.quantity_per_room,
        institution_id:  this.institution ? this.institution.id : null,
        questionnaire_id: this.questionnaire.id,
      }


      this.service.createOrUpdate(modelData).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          if(!this.isEdit){
            this.$router.push(`/classroom_configs/${data.id}/edit`)
          }
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs,this.$t('nao-foi-possivel-realizar-esta-operacao'))
        }
      ).finally(() => { this.$vs.loading.close() })
    },
    goback() {
      this.$router.push('/classroom_configs')
    },
    showHanlingHistoryPopup(show){
      this.popupHistoryView = show
    }
  }
}
</script>

<style>

</style>
