var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", { staticClass: "mb-4" }, [
        _c(
          "div",
          {
            staticClass:
              "grid md:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 flex gap-2",
          },
          [
            _c(
              "div",
              {},
              [
                _c("vs-input", {
                  staticClass: "w-full required",
                  attrs: { id: "name", label: _vm.$t("classroom.name") },
                  model: {
                    value: _vm.model.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "name", $$v)
                    },
                    expression: "model.name",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "grid md:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 flex gap-2",
          },
          [
            _c(
              "div",
              {},
              [
                _c("vs-input", {
                  staticClass: "w-full required",
                  attrs: {
                    id: "room_prefix",
                    label: _vm.$t("classroom.room_prefix"),
                  },
                  model: {
                    value: _vm.model.room_prefix,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "room_prefix", $$v)
                    },
                    expression: "model.room_prefix",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c("vs-input", {
                  staticClass: "w-full required",
                  attrs: {
                    id: "total",
                    type: "number",
                    label: _vm.$t("classroom.total"),
                  },
                  model: {
                    value: _vm.model.total,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "total", $$v)
                    },
                    expression: "model.total",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c("vs-input", {
                  staticClass: "w-full required",
                  attrs: {
                    id: "quantity_per_room",
                    type: "number",
                    label: _vm.$t("classroom.quantity_per_room"),
                  },
                  model: {
                    value: _vm.model.quantity_per_room,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "quantity_per_room", $$v)
                    },
                    expression: "model.quantity_per_room",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c("vs-input", {
                  staticClass: "readonly",
                  attrs: {
                    id: "quantity_total",
                    type: "number",
                    label: _vm.$t("classroom.quantity_total"),
                    disabled: "",
                  },
                  model: {
                    value: _vm.capacity_total,
                    callback: function ($$v) {
                      _vm.capacity_total = $$v
                    },
                    expression: "capacity_total",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm.isAdmin
          ? _c(
              "div",
              { staticClass: "flex w-full align-baseline" },
              [
                _c("select-suggestion", {
                  ref: "select_institution",
                  staticClass: "flex-grow required",
                  attrs: {
                    max: 20,
                    column: "name",
                    model: "Institution",
                    label: _vm.$t("fields.institution"),
                    appendClearOption: true,
                    placeholderText: "Digite o nome da instituição",
                  },
                  model: {
                    value: _vm.institution,
                    callback: function ($$v) {
                      _vm.institution = $$v
                    },
                    expression: "institution",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "flex w-full align-baseline" },
          [
            _c("select-suggestion", {
              ref: "select_questionnaire",
              class: _vm.questionnaire ? "flex-grow" : "flex-grow required",
              attrs: {
                label: _vm.$t("Questionnaire"),
                column: "name,description",
                model: "ContentQuestionnaire",
                appendClearOption: true,
                placeholderText: "Digite o nome do questionário",
              },
              model: {
                value: _vm.questionnaire,
                callback: function ($$v) {
                  _vm.questionnaire = $$v
                },
                expression: "questionnaire",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-full flex gap-2 justify-end mt-4" },
          [
            !_vm.isEdit
              ? _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "classroom_configs.create",
                        expression: "'classroom_configs.create'",
                      },
                    ],
                    staticClass: "float-right mr-2",
                    attrs: { disabled: !_vm.validateForm },
                    on: { click: _vm.createOrUpdate },
                  },
                  [_vm._v(_vm._s(_vm.$t("action.save")))]
                )
              : _vm._e(),
            _vm.isEdit
              ? _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "classroom_configs.edit",
                        expression: "'classroom_configs.edit'",
                      },
                    ],
                    staticClass: "float-right mr-2",
                    attrs: { disabled: !_vm.validateForm },
                    on: { click: _vm.createOrUpdate },
                  },
                  [_vm._v(_vm._s(_vm.$t("action.save")))]
                )
              : _vm._e(),
            _c(
              "vs-button",
              {
                staticClass: "float-right",
                attrs: { color: "primary", type: "border" },
                on: { click: _vm.goback },
              },
              [_vm._v(_vm._s(_vm.$t("action.back")))]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }